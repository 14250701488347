<template>
    <div>
        <Icon name="paperclip"/>
        <span>{{ name }}</span>
        <button @click="download"><Icon name="download"/></button>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { downloadFile } from '@/utils/firebase';

export default {
    name: 'ViewFile',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        path: {
            type: String,
            required: true,
        },
        local: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        async download() {
            const url = this.local ? this.path : await downloadFile(this.path);
            console.log(url);
            const anchor = document.createElement('a');
            anchor.href = await url;
            anchor.target = '_blank';
            anchor.download = '';
            anchor.click();
        },
    },
};
</script>

<style scoped lang="less">
div {
    position: relative;
    font-size: 1.6rem;
    line-height: 1.2;
    margin: 2.2rem 0;
    box-sizing: border-box;
    border-radius: var(--input-border-radius);
    border: 1px solid rgb(var(--green-status-background-color));
    overflow: hidden;
    white-space: nowrap;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    height: 60px;
    box-shadow: 0 10px 20px rgba(var(--semi-light-grey-color), 35%);

    &:first-of-type {
        margin-top: 1.3rem;
    }

    & + & {
        margin-top: 0;
    }

    > svg {
        color: rgb(var(--primary-color));
        margin: 0 20px;
        font-size: var(--size-icon);
        vertical-align: middle;
    }

    > span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
    }

    button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--green-status-text-color), 0%);
        color: rgb(var(--white-color));
        font-size: 2.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background var(--time-transition) var(--cubic-transition);

        svg {
            height: 100%;
            animation: drop-out var(--time-transition) var(--cubic-transition) forwards;
        }

        &:hover {
            background: rgba(var(--green-status-text-color), 90%);

            svg {
                animation: drop-in var(--time-transition) var(--cubic-transition) forwards;
            }
        }
    }
}

@keyframes drop-in {
    0% { transform: translate3d(0, -100%, 0); }
    100% { transform: translate3d(0, 0, 0); }
}

@keyframes drop-out {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(0, 100%, 0); }
}
</style>
