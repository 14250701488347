export default (data) => `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <!--[if gte mso 15]>
        <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>${ data.subject }</title>
        <link rel="stylesheet" id="font-css" href="https://fonts.googleapis.com/css2?family=Rajdhani%3Awght%40400%3B500%3B600&amp;display=swap&amp" type="text/css" media="all">
        <style type="text/css">
            p {
                padding:0;
            }
            table {
                border-collapse:collapse;
            }
            h1,h2,h3,h4,h5,h6 {
                display:block;
                margin:0;
                padding:0;
            }
            img, a img {
                border:0;
                height:auto;
                outline:none;
                text-decoration:none;
            }
            body {
                height:100%;
                margin:0;
                padding:0;
                width:100%;
            }
            #outlook a {
                padding:0;
            }
            img {
                -ms-interpolation-mode:bicubic;
            }
            table {
                mso-table-lspace:0pt;
                mso-table-rspace:0pt;
            }
            p,a,li,td,body,table,blockquote {
                -ms-text-size-adjust:100%;
                -webkit-text-size-adjust:100%;
            }
        </style>
    </head>
    <body style="margin: 0; padding: 0; background-color: #f9f9f9;">
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <td bgcolor="#f9f9f9" style="background-image: url('https://app.selvbyggerplus.dk/img/template/bg.jpg'); background-position: center top; background-repeat: repeat-x;">
                <table align="center" border="0" cellpadding="0" cellspacing="0" width="600" style="border-collapse: collapse;">
                    <tr>
                        <td style="padding: 15px;">&nbsp;</td>
                    </tr>
                    <tr>
                        <td>
                            <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                    <td align="right" valign="middle" bgcolor="#ffffff" style="padding: 27px 30px 13px 30px; border-bottom: 2px solid #f4f4f4;">
                                        <img src="https://app.selvbyggerplus.dk/img/template/logo.png" alt="Selvbygger+ logo">
                                    </td>
                                </tr>
                                <tr>
                                    <td bgcolor="#ffffff" style="padding: 30px 30px 0 30px;">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                            <tr>
                                                <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                    <strong style="font-size: 30px; line-height: 100%;">${ data.subject }</strong><br><br>
                                                    ${ data.message }
                                                </td>
                                            </tr>

                                            ${ data.button ? `
                                            <tr>
                                                <td align="center" valign="top" style="padding: 10px 0 40px 0;">
                                                    <table border="0" cellpadding="0" cellspacing="0">
                                                        <tr>
                                                            <td valign="middle" align="center" style="box-shadow: 0 10px 20px #ceecd2; background-color: #30b34a; border-radius: 30px;padding: 18px 48px; font-family: 'Rajdhani', Arial, sans-serif; font-size: 18px;">
                                                                <a href="${ data.button.link }" style="font-weight: 500; color: #ffffff; text-decoration: none;">${ data.button.text }</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            ` : '' }

                                            ${ data.disclaimer ? `
                                            <tr>
                                                <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                    <strong>VIGTIGT!</strong> Denne mail indeholder et link til dokumenter ifm. accept af en aftale. Send ikke denne mail videre. Du har modtaget mailen fordi du er part i en kontrakt. Hvis du har modtaget denne mail ved en fejl, bedes du slette den straks.
                                                </td>
                                            </tr>
                                            ` : '' }

                                            ${ data.agreement ? `
                                            <tr>
                                                <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                    <strong style="font-size: 20px; line-height: 100%;">Aftalens indhold</strong><br>
                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                                        <tr>
                                                            <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                                <strong style="text-transform: uppercase; color: #30b34a; font-size: 14px;">BESKRIVELSE</strong><br>
                                                                ${ data.agreement.description }
                                                            </td>
                                                            <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                                <strong style="text-transform: uppercase; color: #30b34a; font-size: 14px;">UDFØRELSESSTED</strong><br>
                                                                ${ data.agreement.address }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                                <strong style="text-transform: uppercase; color: #30b34a; font-size: 14px;">ENTREPRISE</strong><br>
                                                                ${ data.agreement.subcontract }
                                                            </td>
                                                            <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                                <strong style="text-transform: uppercase; color: #30b34a; font-size: 14px;">${ data.agreement.payment.type }</strong><br>
                                                                ${ data.agreement.payment.price }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            ${ data.agreement.from ? `
                                                            <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                                <strong style="text-transform: uppercase; color: #30b34a; font-size: 14px;">TIDLIGST OPSTART</strong><br>
                                                                ${ data.agreement.from }
                                                            </td>
                                                            ` : '' }

                                                            ${ data.agreement.to ? `
                                                            <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                                <strong style="text-transform: uppercase; color: #30b34a; font-size: 14px;">SENEST AFLEVERING</strong><br>
                                                                ${ data.agreement.to }
                                                            </td>
                                                            ` : '' }

                                                            ${ data.agreement.days ? `
                                                            <td valign="top" style="padding: 0 0 30px 0; font-family: 'Rajdhani', Arial, sans-serif; color: #4a4a4a; font-size: 17px; line-height: 140%; mso-line-height-rule: exactly;">
                                                                <strong style="text-transform: uppercase; color: #30b34a; font-size: 14px;">TIDSMÆSSIG KONSEKVENS</strong><br>
                                                                ${ data.agreement.days } dage
                                                            </td>
                                                            ` : '' }
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            ` : '' }

                                        </table>
                                    </td>
                                <tr>
                                    <td valign="top" align="center" bgcolor="#141414" style="background-image: url('https://app.selvbyggerplus.dk/img/template/footer.jpg'); background-position: center top; background-repeat: repeat-x; padding: 40px 30px; color: #ffffff; font-family: 'Rajdhani', Arial, sans-serif; font-size: 16px;">
                                        Sendt via <a href="https://selvbyggerplus.dk/" style="color: #ffffff; text-decoration: none;"><strong>Selvbygger+</strong></a>
                                        <br>
                                        - Få kontrol over dit byggeprojekt
                                        <br><br>
                                        <span style="color: #aaaaaa;">Gå til</span> <a href="https://selvbyggerplus.dk/privatlivspolitik/" target="_blank" style="color: #aaaaaa; text-decoration: underline;">selvbyggerplus.dk</a> <span style="color: #aaaaaa;">eller læs vores</span> <a href="https://selvbyggerplus.dk/privatlivspolitik/" target="_blank" style="color: #aaaaaa; text-decoration: underline;">privatlivspolitik</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 15px 0;">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    </body>
    </html>
`;
