<template>
    <SendAddition v-if="send && !isLoadingContract" :id="addition.id" :status="addition.status?.state" :contract="contract" :file="addition.file"/>

    <Modal v-else>
        <template v-if="isLoading">
            <Placeholder element="span" :classes="['status-label']" :width="20"/>
            <h1><Placeholder :width="70"/></h1>
            <Placeholder element="time" :width="35"/>
            <p>
                <Placeholder :width="70"/><br>
                <Placeholder :width="40"/>
            </p>
            <Placeholder element="div" :height="51" :border-radius="15" style="margin: 2.2rem 0 0;"/>
            <Placeholder element="div" :height="51" :border-radius="15" style="margin: 2.2rem 0;"/>
        </template>
        <template v-else>
            <span :class="`status-label ${statusColor}`" :data-tooltip="formatDateToFnsDate(addition.status?.date.toDate())">{{ statusText }}</span>
            <h1>{{ addition.title }}</h1>
            <time>{{ formatDateToFnsDate(addition.created.toDate()) }}</time>
            <p>{{ addition.description }}</p>
            <p class="columns">
                <span>
                    <small>Tidsmæssig konsekvens</small><br>
                    <strong>{{ addition.days }} dage</strong>
                </span>
                <span class="align-left">
                    <small>Fast pris</small><br>
                    <strong class="larger">{{ formatNumberToPrice(addition.price) }}</strong>
                </span>
            </p>
            <h2>Kontrakt</h2>
            <CardList>
                <Placeholder v-if="isLoadingContract" element="li" :classes="['card column-1']" :height="157"/>
                <ContractCard v-else v-bind="contract" :readonly="true"/>
            </CardList>
            <template v-if="addition.file">
                <h2>Bilag</h2>
                <ViewFile v-bind="addition.file"/>
            </template>
        </template>

        <template v-if="isLoadingMails">
            <h2>Historik</h2>
            <p class="columns">
                <span class="longer">
                    <small>Status</small><br>
                    <Placeholder :width="50"/>
                </span>
                <span class="grey">
                    <small>Dato</small><br>
                    <Placeholder :width="80"/>
                </span>
            </p>
            <p class="columns">
                <span class="longer">
                    <small>Status</small><br>
                    <Placeholder :width="50"/>
                </span>
                <span class="grey">
                    <small>Dato</small><br>
                    <Placeholder :width="80"/>
                </span>
            </p>
        </template>
        <template v-if="!isLoadingMails && mails.length">
            <h2>Historik</h2>
            <template v-for="mail in mails" :key="mail.id">
                <p class="columns" v-if="mail.to !== userEmail">
                    <span>
                        <small>Status</small><br>
                        {{ getContractStatusObject(mail.status).label }}
                    </span>
                    <span class="grey">
                        <small>Tidspunkt</small><br>
                        {{ formatDateToFnsDate(mail.delivery.endTime.toDate(), 'd. LLLL yyyy \'kl.\' HH.mm') }}
                    </span>
                </p>
            </template>
        </template>

        <template #footer>
            <template v-if="addition.status?.state !== 'ACCEPTED'">
                <template v-if="!addition.status || addition.status?.state === 'REJECTED'">
                    <ActionButton label="Rediger" icon="edit" :small="true" :secondary="true"
                                  @click="edit"/>
                    <ActionButton label="Slet" icon="trash" :small="true" :secondary="true"
                                  :red="true" @click="isConfirmRemoveDialogOpen = true"/>
                    <ActionButton label="Send tillægskontrakt" icon="paperplane" :small="true"
                                  @click="showSendAdditionPage"/>
                </template>
                <template v-if="addition.status?.state === 'SENT'">
                    <ActionButton label="Send påmindelse" icon="paperplane" :small="true"
                                  :secondary="true" @click="showSendAdditionPage"/>
                </template>
            </template>
        </template>

        <Dialog v-if="isConfirmRemoveDialogOpen">
            <template #title>Slet tillægskontrakt</template>
            <template #default>
                <p>Du er ved at slette en tillægskontrakt. Når først en tillægskontrakt er slettet, kan den ikke genskabes.</p>
                <p>Er du sikker på at vil slette den alligevel?</p>
            </template>
            <template #actions>
                <ActionButton icon="trash" label="Slet alligevel" :small="true" :red="true" @click="remove"/>
                <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true" @click="isConfirmRemoveDialogOpen = false"/>
            </template>
        </Dialog>
    </Modal>

</template>

<script>
import Modal from '@/components/Modal.vue';
import { useRoute, useRouter } from 'vue-router';
import { onUnmounted, reactive, toRefs } from 'vue';
import methods from '@/utils/methods';
import ViewFile from '@/components/ViewFile.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import useState from '@/store';
import { additionMailsCollection, additionsCollection, contractsCollection } from '@/utils/collections';
import Placeholder from '@/components/Placeholder.vue';
import Dialog from '@/components/Dialog.vue';
import { auth } from '@/utils/firebase';
import CardList from '@/components/CardList.vue';
import ContractCard from '@/components/cards/ContractCard.vue';
import SendAddition from '@/views/view/SendAddition.vue';

export default {
    name: 'Addition',
    components: {
        CardList,
        ContractCard,
        Modal,
        ViewFile,
        ActionButton,
        SendAddition,
        Placeholder,
        Dialog,
    },
    setup() {
        const { modalData } = useState();
        const hasLocalData = Object.entries(modalData.value).length;

        const router = useRouter();
        if (!hasLocalData) router.replace({ query: null });

        const route = useRoute();
        const data = reactive({
            contract: route.params.contract,
            addition: {
                id: route.params.id,
                ...modalData.value,
            },
            isLoading: !hasLocalData,
            mails: [],
            isLoadingMails: true,
            isLoadingContract: true,
            send: route.query.action === 'send' && hasLocalData,
        });

        if (data.isLoading) {
            additionsCollection(data.contract).doc(data.addition.id).get().then((snapshot) => {
                data.addition = { id: snapshot.id, ...snapshot.data() };
                data.isLoading = false;
            });
        }

        const mailUnsubscribe = additionMailsCollection(data.contract, data.addition.id).orderBy('delivery.endTime', 'desc').onSnapshot((querySnapshot) => {
            data.mails = querySnapshot.docs.map((snapshot) => ({
                id: snapshot.id,
                ...snapshot.data(),
            }));
            data.isLoadingMails = false;
        }, (err) => {
            console.log(err);
        });

        contractsCollection().doc(data.contract).get().then((snapshot) => {
            data.contract = { id: snapshot.id, ...snapshot.data() };
            data.isLoadingContract = false;
        });

        onUnmounted(() => {
            mailUnsubscribe();
        });

        return { ...toRefs(data) };
    },
    data() {
        return {
            isConfirmRemoveDialogOpen: false,
            userEmail: auth.currentUser.email,
        };
    },
    watch: {
        $route(path) {
            this.send = path.query.action === 'send';
        },
    },
    mixins: [methods],
    computed: {
        statusText() {
            return this.getContractStatusObject(this.addition.status?.state).label;
        },
        statusColor() {
            return this.getContractStatusObject(this.addition.status?.state).color;
        },
    },
    methods: {
        showSendAdditionPage() {
            this.$router.push({ query: { action: 'send' } });
        },
        edit() {
            this.$router.push({ name: 'edit-addition' });
        },
        async remove() {
            this.isConfirmRemoveDialogOpen = false;
            try {
                await additionsCollection(this.contract.id).doc(this.addition.id).delete();
                await this.$router.push({ name: 'view-contract', params: { id: this.contract.id } });
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
